import React, { useMemo, useRef, useState } from "react";
import { Stage, Layer, Image as KonvaImage } from "react-konva";
import { Rectangle } from "./Rectangle";
import { useAppState } from "../../store/store";
import zcIcon from "../../assets/images/emoji/cz.png";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { ImageEditHeader } from "./ImageEditHeader";
import { defaultSticker, StickerInfo, Stickers } from "./Stickers";
import { uploadImageToVercelStorage } from "./VercelUpload";
import TwitterShare from "./TwitterShare";
import { getDrawImageSize } from "./common";
import { removeBg } from "./RemoveBg";
import classNames from "classnames";
import styles from "./index.module.scss";
import { cutoutFace } from "./CutOut";
import thumbIcon from "../../assets/images/body/thumb.png";

interface Sticker {
  id: string;
  x: number;
  y: number;
  fill: string;
  width: number;
  height: number;
}

const initialRectangles: Sticker[] = [
  {
    id: "rect1",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    fill: "",
  },
];

const ImageUploaderWithStickers: React.FC = () => {
  // is mobile?
  const { width: MAX_WIDTH, height: MAX_HEIGHT } = getDrawImageSize();
  const store = useAppState();
  const avatar = store.uploadedImage;
  const setImage = store.setUploadedImage;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const stageRef = useRef<any>(null);
  // const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [selectedId, selectShape] = React.useState<any>(null);
  const [rectangles, setRectangles] =
    React.useState<Sticker[]>(initialRectangles);
  const [bodyImage, setBodyImage] = useState<StickerInfo>(defaultSticker);
  const [drawImageWidth, setDrawImageWidth] = useState<number>(MAX_WIDTH);
  const [drawImageHeight, setDrawImageHeight] = useState<number>(MAX_HEIGHT);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  //stageRef.current ? stageRef.current.toDataURL() : ""
  const finalImageContent = useMemo(() => {
    return stageRef.current ? stageRef.current.toDataURL() : "";
  }, [stageRef]);

  const bodyImageObj = new Image();
  bodyImageObj.src = bodyImage.icon;

  const bgImageObj = new Image();
  bgImageObj.src = bodyImage.bgIcon || "";

  const previewImageObj = new Image();
  previewImageObj.src = bodyImage.previewIcon;

  const checkDeselect = (e: any) => {
    const imageObject = e.target.getStage()?.children[0]?.children[0];
    const clickedOnEmpty = e.target === imageObject;
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    console.log("file", file);

    if (!file) return;
    setIsUploading(true);
    let avatarWidth = 180;
    let avatarHeight = 180;
    let avatarXOffset = 0;
    let avatarYOffset = 0;
    if (window.innerWidth < 500) {
      avatarWidth = 100;
      avatarHeight = 100;
      avatarYOffset = 10;
    }
    const newRectId = `rect${rectangles.length + 1}`;
    try {
      throw new Error("");
      const trimedFile = await cutoutFace(file!);
      const fileSize = trimedFile?.size || 0;

      // const trimedFile = await removeBg(file!);
      console.log("trimedFile", trimedFile);
      console.log("trimedFile size is:", trimedFile.size);
      if (fileSize < 200) {
        throw new Error("File size is too small");
      }

      const blob = URL.createObjectURL(trimedFile);

      setRectangles([
        {
          id: newRectId,
          x: bodyImage.headerX,
          y: bodyImage.headerY,
          width: avatarWidth,
          height: avatarHeight,
          fill: blob,
        },
      ]);
    } catch (error) {
      console.error("error", error);

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setImage(img);
        };
        img.src = e.target?.result as string;

        console.log("img", img.width, img.height);

        setRectangles([
          {
            id: newRectId,
            x: bodyImage.headerX,
            y: bodyImage.headerY + avatarYOffset,
            width: avatarWidth,
            height: avatarHeight,
            fill: img.src,
          },
        ]);
      };
      reader.readAsDataURL(file);
    } finally {
      setIsUploading(false);
      selectShape(newRectId);
    }
  };

  const handleChangeBody = (sticker: StickerInfo) => {
    setBodyImage(sticker);
    setRectangles([]);
  };

  const handleDownload = async () => {
    const uri = await getFinalImageContent();
    const link = document.createElement("a");
    link.download = "thefour.png";
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getFinalImageContent = async () => {
    selectShape(null);
    await new Promise((resolve) => setTimeout(resolve, 100));
    const uri = stageRef.current.toDataURL();
    return uri;
  };

  //is user uploaded image
  const isUserUploadedImage = !!rectangles[0] && !!rectangles[0].fill;
  console.log('isUserUploadedImage', rectangles.length);

  return (
    <Flex width="100vw" height={`calc(100vh - 74px)`} flexDirection="column">
      <Flex>
        <ImageEditHeader onSave={handleDownload} />
      </Flex>
      <Flex width="100%" flexDirection="column" alignItems="center">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          accept="image/*"
          style={{ display: "none" }}
        />
        <div
          style={{
            border: "1px solid #7344f2",
            display: "inline-block",
          }}
        >
          <Stage
            style={{ borderRadius: "20px" }}
            ref={stageRef}
            width={drawImageWidth}
            height={drawImageHeight}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
          >
            <Layer>
              {!isUserUploadedImage && (
                <KonvaImage
                  style={{ borderRadius: "20px" }}
                  image={previewImageObj}
                  width={drawImageWidth}
                  height={drawImageHeight}
                />
              )}
              {isUserUploadedImage && (
                <>
                  <KonvaImage
                    style={{ borderRadius: "20px" }}
                    image={bgImageObj}
                    width={drawImageWidth}
                    height={drawImageHeight}
                  />
                  {rectangles.map((rect, i) => {
                    return (
                      <Rectangle
                        key={i}
                        shapeProps={rect}
                        isSelected={rect.id === selectedId}
                        onSelect={() => {
                          selectShape(rect.id);
                        }}
                        onChange={(newAttrs) => {
                          const rects = rectangles.slice();
                          rects[i] = newAttrs;
                          setRectangles(rects);
                        }}
                      />
                    );
                  })}
                  <KonvaImage
                    style={{ borderRadius: "20px" }}
                    image={bodyImageObj}
                    width={drawImageWidth}
                    height={drawImageHeight}
                  />
                </>
              )}
            </Layer>
          </Stage>
        </div>
        {/* {!image && (
          <Flex
            width={MAX_WIDTH}
            height={MAX_HEIGHT}
            borderRadius={10}
            bg={"#7344f2"}
            cursor="pointer"
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            <Flex
              color="white"
              width="100%"
              flex={1}
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <span>Upload Image</span>
            </Flex>
          </Flex>
        )} */}
        <Flex
          alignItems="center"
          gap={8}
          color="white"
          mt={4}
          mb={2}
          justifyContent="space-between"
        >
          <button
            disabled={isUploading}
            className={classNames(styles.uploadButton, {
              [styles.forbidden]: isUploading,
            })}
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            <Flex gap={2} alignItems="center">
              Upload Avatar
              {isUploading && <Spinner color="blue.300" />}
            </Flex>
          </button>
          <TwitterShare getBase64Image={getFinalImageContent} />
        </Flex>
        {true && <Stickers addSticker={handleChangeBody} />}
      </Flex>
    </Flex>
  );
};

export default ImageUploaderWithStickers;
