import { useState, useEffect } from "react";
import { differenceInSeconds } from "date-fns";
import { fromZonedTime } from "date-fns-tz";

const targetDate = fromZonedTime("2024-09-29 12:00:00", "America/Los_Angeles");

const useCountdown = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const target = new Date(targetDate);
    const totalSeconds = differenceInSeconds(target, now);

    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [targetDate]);

  return timeLeft;
};

export default useCountdown;
