import React, { useRef, useState } from "react";
import axios from "axios";
import { TwitterShareButton, TwitterIcon } from "react-share";
import { SocialIcon } from "react-social-icons";
import {
  Box,
  useDisclosure,
  Image,
  Spinner,
  Flex,
  Button,
} from "@chakra-ui/react";
import twitterIcon from "../../assets/images/social/twitter.webp";
import classNames from "classnames";
import styles from "./index.module.scss";
import { testS3send } from "./S3Uploader";
import { uploadImageToVercelStorage } from "./VercelUpload";

const baseUrl = "https://www.bnbfour.meme/";
const text = `MAKE BNB GREAT AGAIN! $FOUR `;
const hashTag = "FOUR";
const TwitterShare = ({
  getBase64Image,
}: {
  getBase64Image: () => Promise<string>;
}) => {
  const shareButtonRef = useRef<any>(null);
  // mobile share button ref
  const mobileShareButtonRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const message = encodeURIComponent(`${text} ${imageUrl} #${hashTag}`);
  const twitterUrl = `twitter://post?message=${message}`;
  const navigatorShare = navigator.userAgent;
  console.log("navigatorShare", navigatorShare);

  // client secret: d35492a17ac630d935df75c5ca905bf3e6cf41fd
  // client id: f8e71f7e37e32e3

  const getTrimedData = async () => {
    const base64Image = await getBase64Image();
    return base64Image
      ? base64Image.replace(/^data:image\/(png|jpg);base64,/, "")
      : "";
  };
  const uploadImage = async () => {
    const base64Image = await getTrimedData();
    const link = await uploadImageToVercelStorage(base64Image);
    setImageUrl(link);
    console.log("link", link);

    return link;
  };

  let beforeShareComponent = null;
  if (isLoading) {
    beforeShareComponent = (
      <Box style={{ width: "40px", height: "40px" }}>
        <Spinner color="blue.500" />
      </Box>
    );
  } else if (!imageUrl) {
    beforeShareComponent = (
      <Box
        style={{ width: "40px", height: "40px", cursor: "pointer" }}
        onClick={async (e) => {
          const base64Image = await getTrimedData();
          if (!base64Image) {
            return;
          }
          setIsLoading(true);
          await uploadImage();
          await new Promise((resolve) => setTimeout(resolve, 100));
          console.log("done uploading image to imgur", imageUrl);
          setIsLoading(false);
          if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            mobileShareButtonRef.current.click();
          } else {
            shareButtonRef.current.click();
          }
          console.log("share button clicked", shareButtonRef.current);
        }}
      >
        <Image src={twitterIcon} />
      </Box>
    );
  }

  return (
    <>
      <Button
        style={{ display: "none" }}
        ref={mobileShareButtonRef}
        onClick={() => {
          window.location.href = twitterUrl;
          // alert(JSON.stringify(navigatorShare));
        }}
      >
        share
      </Button>
      {beforeShareComponent}
      <Flex className={classNames({ [styles.hide]: !!beforeShareComponent })}>
        <TwitterShareButton
          ref={shareButtonRef}
          url={imageUrl}
          title={text}
          hashtags={[hashTag]}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </Flex>
    </>
  );
};

export default TwitterShare;
