import React from "react";
import {
  Transformer,
  Image as KonvaImage,
} from "react-konva";

type RectProps = {
  shapeProps: any;
  isSelected: boolean;
  onSelect: () => void;
  onChange: (newAttrs: any) => void;
};

export const Rectangle = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
}: RectProps) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  const imageObj = new Image();
  imageObj.src = shapeProps.fill;

  // React.useEffect(() => {
  //   if (isSelected) {
  //     // we need to attach transformer manually
  //     (trRef.current as any).nodes([shapeRef.current]);
  //     (trRef.current as any).getLayer().batchDraw();
  //   }
  // }, [isSelected]);

  return (
    <React.Fragment>
      <KonvaImage
        // onClick={onSelect}
        // onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        fill="transparent"
        image={imageObj}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node: any = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef as any}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};
