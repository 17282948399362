import { Flex, Image } from "@chakra-ui/react";
import twitterExample from "../../assets/images/misc/twitter-example.png";
import gateioImage from "../../assets/images/cex/gateio.png";
import ascendexImage from "../../assets/images/cex/ascendex.png";
import mexcImage from "../../assets/images/cex/mexc.png";
import bitmartImage from "../../assets/images/cex/bitmart.png";

import styles from "./index.module.scss";

type CexItemProps = {
  image: string;
  title: string;
  link?: string;
};

const CexItems: CexItemProps[] = [
  {
    image: gateioImage,
    title: "Gate.io",
    link: "https://www.gate.io/zh/trade/FOUR_USDT",
  },
  {
    image: mexcImage,
    title: "MEXC",
    link: "https://www.mexc.com/zh-CN/exchange/FOUR_USDT?_from=search_spot_trade",
  },
  {
    image: bitmartImage,
    title: "BitMart",
    link: "https://www.bitmart.com/trade/en-US?symbol=FOUR_USDT",
  },
  {
    image: ascendexImage,
    title: "AscendEX",
    link: "https://ascendex.com/zh-cn/cashtrade-spottrading/usdt/$four",
  },
];

export default function Twitter() {
  return (
    <Flex
      width="100%"
      justifyContent="center"
      flexDirection={"column"}
      alignItems={"center"}
      mt={[50, 150]}
      mb={50}
    >
      <Image
        src={twitterExample}
        alt="twitter example"
        className={styles.twitter}
      />

      <div className={styles.cexContainer} id="cex">
        <div className={styles.cexTitle}>BUY it now</div>
        <Flex className={styles.cexItemsContainer}>
          {CexItems.map((item, index) => (
            <CexItem key={index} {...item} />
          ))}
        </Flex>
      </div>
    </Flex>
  );
}

function CexItem(props: CexItemProps) {
  const { image, title, link } = props;

  const handleClick = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };
  return (
    <Flex className={styles.cexItem} onClick={handleClick}>
      <Image src={image} alt={title} />
    </Flex>
  );
}
