import { Box, Flex, Image } from "@chakra-ui/react";
import logo from "../../assets/images/logo.png";
import styles from "./index.module.scss";

import BuyButton from "../BuyButton";
import { socialIcons } from "../../commons";

export default function Header() {
  return (
    <Box className={styles.container}>
      <img
        src={logo}
        className={styles.logo}
        alt="logo"
        onClick={() => {
          window.location.href = "/";
        }}
      />
      <Flex className={styles.socials}>
        {socialIcons.map((item, index) => (
          <a
            href={item.link.length > 0 ? item.link : "#"}
            target={item.link.length > 0 ? "_blank" : ""}
            rel={item.link.length > 0 ? "noopener noreferrer" : ""}
            key={index}
          >
            <Image
              key={index}
              src={item.imgSrc}
              className={styles.socialIcon}
              alt="social icon"
            />
          </a>
        ))}
      </Flex>
      <BuyButton />
    </Box>
  );
}
