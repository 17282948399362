import { Box, Flex, Image } from "@chakra-ui/react";
import footerImage from "../../assets/images/banner/footer-avatar.png";
import styles from "./index.module.scss";
import { socialIcons } from "../../commons";

export default function Footer() {
  return (
    <Flex className={styles.container}>
      <Image
        src={footerImage}
        className={styles.footerImage}
        alt="footer image"
      />
      {
        <Flex className={styles.socials}>
          {socialIcons.map((item, index) => (
            <a
              href={item.link.length > 0 ? item.link : "#"}
              target={item.link.length > 0 ? "_blank" : ""}
              rel={item.link.length > 0 ? "noopener noreferrer" : ""}
              key={index}
            >
              <Image
                key={index}
                src={item.imgSrc}
                className={styles.socialIcon}
                alt="social icon"
              />
            </a>
          ))}
        </Flex>
      }
      <Flex className={styles.footerText}>
        <Box>Copyright 2024 | All Rights Reserved</Box>
        <Box>fourbnb111@gmail.com</Box>
        <Box className={styles.caText}>BSC CA: 0x21FD16cD0eF24A49D28429921e335bb0C1bfAdB3</Box>
        <Box className={styles.caText}>ETH CA: 0xE6D6043D19e7479d1ffe76dDDc7F68467234462b</Box>
      </Flex>
    </Flex>
  );
}
