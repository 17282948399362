import { Flex, Image } from "@chakra-ui/react";
import binanceIcon from "../../assets/images/binance.png";
import step1 from "../../assets/images/slogan/Step1.png";
import step2 from "../../assets/images/slogan/Step2.png";
import step3 from "../../assets/images/slogan/Step3.png";

import styles from "./index.module.scss";

export default function Slogan() {
  return (
    <div className={styles.container}>
      <div className={styles.slogan}>
        <div className={styles.sloganTitle}>LET'S MAKE</div>
        <Image
          src={binanceIcon}
          className={styles.binanceIcon}
          alt="binance icon"
        />
        <div className={styles.sloganText}>BINANCE GREAT AGAIN</div>
      </div>

      <Flex mt={"120px"} className={styles.sloganItemWrapper}>
        <img src={step1} alt={"step1"} />
        <img src={step2} alt={"step2"} className={styles.step2} />
        <img src={step3} alt={"step3"} />
      </Flex>
    </div>
  );
}
