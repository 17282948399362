import { create } from "zustand";

export type AppState = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;

  uploadedImage: any;
  setUploadedImage: (uploadedImage: any) => void;
};

export const useAppState = create<AppState>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),

  uploadedImage: null,
  setUploadedImage: (uploadedImage: any) => set({ uploadedImage }),
}));
