import swapExample from "../../assets/images/misc/exchange-example.png";
import curveIcon from "../../assets/images/misc/curve.png";
import binanceBull from "../../assets/images/binance-bull.png";
import { Flex, Text, Image, Box, useDisclosure } from "@chakra-ui/react";
import styles from "./index.module.scss";
import BuyButton from "../BuyButton";
import { useAppState } from "../../store/store";

export default function Tokennomics() {
  const store = useAppState();
  return (
    <>
      <Flex className={styles.container}>
        <Text className={styles.title}>TOKENOMICS</Text>

        <Flex className={styles.supply}>
          <Box>4 billion $FOUR Token Supply</Box>
          <Box>BUY/SELL tax:0%</Box>
          <BuyButton bordered />
        </Flex>

        <Text className={styles.title}>ROADMAP</Text>

        <Image src={curveIcon} className={styles.curveIcon} alt="curveIcon" />

        <Text className={styles.title}>TRADE ON FLOOR</Text>

        <a
          // href={`https://app.d3x.exchange/trade?pair=FOUR-USD&showInsight=1`}
          // target={"_blank"}
          href="#cex"
          rel={"noopener noreferrer"}
          className={styles.swapExample}
        >
          <Image
            src={swapExample}
            // className={styles.swapExample}
            alt="swapExample"
            // onClick={() => store.setIsOpen(true)}
          />
        </a>
        <Flex className={styles.bullWrapper}>
          <Image
            src={binanceBull}
            className={styles.binanceBull}
            alt="binanceBull"
          />
          <Flex flexDirection="column" className={styles.bullTxtWrapper}>
            <Box>$FOUR Means no FUD;</Box>
            <Box>$FOUR Means 4-Months;</Box>
            <Box>$FOUR Means $4 Billion;</Box>
            <Box>$FOUR Means 4 BNB;</Box>
            <Box>$FOUR Means 4 Everything and 4ever!</Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
