import xIcon from "./assets/images/social/x.png";
import tgIcon from "./assets/images/social/tg.png";
// import uniswapIcon from "./assets/images/social/unisawap.png";
import zcIcon from "./assets/images/social/xz.png";
// import etherscanIcon from "./assets/images/social/etherscan.png";
// import blackFourIcon from "./assets/images/common/four-black.png";
import bscscanIcon from "./assets/images/social/bscscan.png";
import pancakeswapIcon from "./assets/images/social/pancakeswap.png";
import cmcIcon from "./assets/images/social/cmc.png";
import okxIcon from "./assets/images/social/okx.webp";
import cgIcon from "./assets/images/social/cg.png";
import d3xIcon from "./assets/images/social/d3x.png";

// Web: https://www.bnbfour.meme/
// X: https://x.com/bnbfour
// Telegram: https://t.me/bnbfour
export const socialIcons = [
  { imgSrc: xIcon, link: "https://x.com/bnbfour" },
  { imgSrc: tgIcon, link: "https://t.me/bnbfour" },
  {
    imgSrc: pancakeswapIcon,
    link: "https://pancakeswap.finance/swap?outputCurrency=0x21FD16cD0eF24A49D28429921e335bb0C1bfAdB3&inputCurrency=BNB",
  },
  {
    imgSrc: d3xIcon,
    link: "https://app.d3x.exchange/trade?pair=FOUR-USD&showInsight=1",
  },
  {
    imgSrc: zcIcon,
    link: "https://www.dextools.io/app/en/bnb/pair-explorer/0x6f24a3db514370ca6c0e94144d71c1b3d2646488?t=1720710509345",
  },
  {
    imgSrc: bscscanIcon,
    link: "https://bscscan.com/address/0x21fd16cd0ef24a49d28429921e335bb0c1bfadb3",
  },
  {
    imgSrc: cmcIcon,
    link: "https://coinmarketcap.com/currencies/4-meme",
  },
  {
    imgSrc: cgIcon,
    link: "https://www.coingecko.com/en/coins/4",
  },
];

export const buyLinkInfos = [
  {
    imgSrc: pancakeswapIcon,
    link: "https://pancakeswap.finance/swap?outputCurrency=0x21FD16cD0eF24A49D28429921e335bb0C1bfAdB3&inputCurrency=BNB",
    name: "PancakeSwap",
  },
  {
    imgSrc: okxIcon,
    link: "https://www.okx.com/web3/dex-swap?inputChain=56&inputCurrency=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&outputChain=56&outputCurrency=0x21fd16cd0ef24a49d28429921e335bb0c1bfadb3",
    name: "OKX dex",
  },
];
