import styles from "./index.module.scss";
// import bgYellow from "../../assets/images/banner/bar-bg-yellow.png";
// import bgBlue from "../../assets/images/banner/bar-bg-blue.png";
import avatar from "../../assets/images/banner/banner-avatar.png";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
// import { getDuration } from "../../utils/data";
import useCountdown from "../../hooks/useCountdown";
import jailImage from "../../assets/images/jail.png";

export default function Banner() {
  return (
    <div className={styles.container}>
      <Flex
        color="white"
        width="100%"
        justifyContent="center"
        fontSize="35px"
        className={styles.title}
      >
        4 EVERYTHING
      </Flex>

      {/* <Image src={avatar} className={styles.avatar} alt="avatar" /> */}
      {/*  */}
      {/* <Image src={bgYellow} className={styles.bgYellow} alt="bgYellow" />
      <Image src={bgBlue} className={styles.bgBlue} alt="bgBlue" /> */}
      {/* <Flex
        justifyContent={"space-around"}
        alignItems={"center"}
        position={"absolute"}
        left={["15%"]}
        top={["25%", "35%"]}
        zIndex={"3"}
        gap={"2rem"}
        className={styles.countdownWrapper}
      >
        <Flex className={styles.countdown}>
          <Box textShadow={"0px 0px 20px rgb(0, 0, 0)"}>COUNTDOWN</Box>
          <Box textShadow={"0px 0px 20px rgb(0, 0, 0)"}>TO LIBERATION</Box>
        </Flex>
        <CountdownComponent />
      </Flex> */}
      <Image
        src={jailImage}
        className={styles.jail}
        alt="jail"
        // height={["600px", "100vh"]}
      />
    </div>
  );
}

function CountdownComponent() {
  // const targetTimestamp = new Date("2024-09-29T20:00:00").getTime();
  // const targetTimestamp = 1727640000;
  // const duration = getDuration();
  // console.log("duration", duration);
  // const countdown = useCountdown({
  //   minutes: 1,
  //   seconds: 30,
  //   format: "mm:ss",
  //   autoStart: true,
  //   onCompleted: () => console.log("onCompleted"),
  // });

  // console.log(countdown);
  const duration = useCountdown();

  console.log("countdown", duration);

  return (
    <Flex className={styles.countdownComponent}>
      <Flex className={styles.countdownCard}>
        <Text className={styles.countdownNumber}>
          {String(duration.days).padStart(2, "0")}
        </Text>
        <Text className={styles.countdownUnit}>D</Text>
      </Flex>
      <Flex className={styles.countdownCard}>
        <Text className={styles.countdownNumber}>
          {String(duration.hours).padStart(2, "0")}
        </Text>
        <Text className={styles.countdownUnit}>H</Text>
      </Flex>
      <Flex className={styles.countdownCard}>
        <Text className={styles.countdownNumber}>
          {String(duration.minutes).padStart(2, "0")}
        </Text>
        <Text className={styles.countdownUnit}>M</Text>
      </Flex>
      <Flex className={styles.countdownCard}>
        <Text className={styles.countdownNumber}>
          {String(duration.seconds || "0").padStart(2, "0")}
        </Text>
        <Text className={styles.countdownUnit}>S</Text>
      </Flex>
    </Flex>
  );
}
