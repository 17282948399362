import { Flex, Button, Icon } from "@chakra-ui/react";
import { MdOutlineBackspace } from "react-icons/md";
import { MdOutlineSaveAlt } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { useAppState } from "../../store/store";
import TwitterShare from "./TwitterShare";

export const ImageEditHeader = (props: { onSave: () => void }) => {
  const store = useAppState();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const setIsEditImagePage = () => {
    store.setUploadedImage(null);
    queryParams.set("tab", "default");
    navigate(`${window.location.pathname}?${queryParams.toString()}`);
    //change the url param to edit
  };

  console.log("store.uploadedImage", store.uploadedImage?.src);
  

  const { onSave } = props;
  return (
    <Flex
      width="100%"
      height="50px"
      px={4}
      alignItems="center"
      justifyContent="space-between"
    >
      <Icon
        as={MdOutlineBackspace}
        color="white"
        w={8}
        h={8}
        onClick={setIsEditImagePage}
        cursor="pointer"
      />
      <Icon
        as={MdOutlineSaveAlt}
        color="white"
        w={8}
        h={8}
        onClick={onSave}
        cursor="pointer"
      />
    </Flex>
  );
};
