import { Box, Flex, Image } from "@chakra-ui/react";
import czIcon from "../../assets/images/emoji/cz.png";
import handIcon from "../../assets/images/body/hand.png";
import thumbIcon from "../../assets/images/body/thumb.png";
import donutIcon from "../../assets/images/body/donut.png";
import handCzIcon from "../../assets/images/body/hand-cz.png";

import { getDrawImageSize } from "./common";
const { width: MAX_WIDTH, height: MAX_HEIGHT } = getDrawImageSize();

export type StickerInfo = {
  previewIcon: string;
  bgIcon?: string;
  icon: string;
  headerX: number;
  headerY: number;
};

export const defaultSticker: StickerInfo = {
  // previewIcon: handIcon,
  previewIcon: handCzIcon,
  bgIcon: thumbIcon,
  icon: handIcon,
  headerX: MAX_WIDTH * 0.36,
  headerY: MAX_HEIGHT * 0.251,
};

export const Stickers = (props: {
  addSticker: (icon: StickerInfo) => void;
}) => {
  const stickers: StickerInfo[] = [
    { ...defaultSticker },
    // {
    //   icon: handIcon,
    //   headerX: MAX_WIDTH * 0.4,
    //   headerY: MAX_HEIGHT * 0.1,
    // },
    // {
    //   icon: coffee,
    //   headerX: MAX_WIDTH * 0.4,
    //   headerY: MAX_HEIGHT * 0.1,
    // },
    // {
    //   icon: walk,
    //   headerX: MAX_WIDTH * 0.4,
    //   headerY: MAX_HEIGHT * 0.1,
    // },
    // {
    //   icon: dumbbell,
    //   headerX: MAX_WIDTH * 0.4,
    //   headerY: MAX_HEIGHT * 0.1,
    // },
    // {
    //   icon: laptop,
    //   headerX: MAX_WIDTH * 0.4,
    //   headerY: MAX_HEIGHT * 0.1,
    // },
  ];
  return (
    <Flex gap={4} flexWrap="wrap">
      {stickers.map((sticker, index) => {
        return (
          <Box
            key={index}
            onClick={() => {
              props.addSticker(sticker);
            }}
          >
            <Sticker key={index} icon={sticker.icon} />
          </Box>
        );
      })}
    </Flex>
  );
};

const Sticker = (props: { icon: string }) => {
  const size = 12;
  return (
    <Flex w={size} h={size} cursor={"pointer"}>
      <Image src={props.icon} />
    </Flex>
  );
};
