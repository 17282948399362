import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Twitter from "./components/Twitter";
import Slogan from "./components/Slogan";
import Tokennomics from "./components/Tokennomics";
import Footer from "./components/Footer";
// 1. import `ChakraProvider` component
import { Box, ChakraProvider, Flex, Icon } from "@chakra-ui/react";
import BuyModal from "./components/BuyModal";
import ImageUploaderWithStickers from "./components/ImageEdit/ImageUploaderWithStickers";
import { useLocation, useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { Helmet } from "react-helmet";

function App() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);

  const tab = queryParams.get("tab");
  const mog = queryParams.get("mog");
  console.log("mog", mog);

  const isEditImagePage = tab === "edit";

  const setIsEditImagePage = () => {
    queryParams.set("tab", "edit");
    navigate(`${window.location.pathname}?${queryParams.toString()}`);
    //change the url param to edit
  };

  // const [isEditImagePage, setIsEditImagePage] = React.useState(false);
  const defaultComponent = (
    <>
      <div className="App">
        <Header />
        <Flex width="100vw" justifyContent="center" color="white">
          <Flex
            cursor="pointer"
            fontSize={20}
            onClick={setIsEditImagePage}
            gap={2}
            mt={2}
            px={8}
            py={2}
            border={"1px solid white"}
            borderRadius={50}
          >
            <Icon as={FiPlus} color="white" w={8} h={8} />
            PFP with FOUR!
          </Flex>
        </Flex>
        <Banner />
        <Twitter />
        <Slogan />
        <Tokennomics />
        <Footer />
      </div>
    </>
  );
  const editImageComponent = (
    <div className="App">
      <Header />
      <ImageUploaderWithStickers />
    </div>
  );
  return (
    <>
      <Helmet>{mog && <meta name="twitter:image" content={mog} />}</Helmet>
      <ChakraProvider>
        {isEditImagePage ? editImageComponent : defaultComponent}
        {/* <BuyModal /> */}
      </ChakraProvider>
    </>
  );
}

export default App;
