import { put } from "@vercel/blob";

const apikey = process.env.REACT_APP_BLOB_READ_WRITE_TOKEN!;

export const uploadImageToVercelStorage = async (file: string) => {
  const imageBuffer = new Buffer(file, "base64");
  const { url } = await put("images/thefour.png", imageBuffer, {
    access: "public",
    token: apikey,
  });
  return url;

  // const response = await fetch(`/api/avatar/upload?filename=images/czg1.png`, {
  //   method: "POST",
  //   body: file,
  // });
  // return response;
};
