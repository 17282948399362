import { Flex, Image, useDisclosure } from "@chakra-ui/react";
import styles from "./index.module.scss";
import blackFourIcon from "../../assets/images/common/four-black.png";
import classNames from "classnames";
import { useAppState } from "../../store/store";

export default function BuyButton(props: { bordered?: boolean }) {
  const store = useAppState();
  return (
    <>
      <a
        // href={`https://app.d3x.exchange/trade?pair=FOUR-USD&showInsight=1`}
        href={`#cex`}
        // target={"_blank"}
        rel={"noopener noreferrer"}
        className={styles.link}
      >
        <Flex
          className={classNames(
            styles.buyBtn,
            props.bordered && styles.bordered
          )}
          // onClick={() => store.setIsOpen(true)}
          // onClick={() => store.setIsOpen(true)}
        >
          {/* Purchase Now{" "} */}
          BUY FOUR NOW
          <Image
            src={blackFourIcon}
            className={styles.blackFourIcon}
            alt="social icon"
          />
        </Flex>
      </a>
    </>
  );
}
